@import '../../styles/common/common.scss';

.mobile_header {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: rem(115px);

    .topbar {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: var(--partners-menu-container-height-mobile);
        padding: 0 10px;

        .home {
            overflow: hidden;
            position: relative;
            height: 90%;
            width: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-decoration: none;
            gap: 1em;

            div:first-child {
                position: relative;

                min-width: var(--partners-menu-container-height-mobile);
                height: var(--partners-menu-container-height-mobile);
            }
            div {
                color: var(--utmb-color-default);
                text-transform: uppercase;
                text-align: center;
            }
        }
        &.is_dark {
            .home {
                div {
                    color: var(--utmb-color-white);
                }
            }
        }

        .partners {
            width: 45%;
            display: flex;
            justify-content: center;
        }

        &.is_dark:before,
        &.is_dark:after {
            background-color: var(--utmb-color-white);
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            height: rem(1px);
            background-color: var(--utmb-color-primary);
        }

        &:before {
            left: 0;
            width: 50%;
        }

        &:after {
            left: 50%;
            width: 100vw;
            transform: rotate(-65deg);
            transform-origin: bottom left;
        }

        @include bp(lg) {
            padding: 0;
        }
    }

    /*
    .image_container {
        margin: auto;

        .image_wrapper {
            position: relative;
            width: rem(120px);
            height: rem(100px);
            margin: 0 auto;
        }
    }
    */
}
