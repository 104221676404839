@import '../../styles/common/common.scss';

.breadcrumbs {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    color: var(--utmb-color-blue-light);

    .item {
        align-items: center;
        align-content: center;
        display: flex;
        list-style: none;
        justify-content: space-between;
        min-height: rem(44px);
        line-height: rem(24px);
        text-transform: capitalize;

        span {
            margin-right: rem(30px);
        }
        &:not(:last-of-type) {
            margin-right: rem(30px);
        }

        a {
            color: inherit;
            text-decoration: none;

            @include hover-focus {
                text-decoration: underline;
            }
        }

        svg {
            transform: rotate(-90deg);
        }
    }

    .active {
        font-weight: bold;
        cursor: default;
        text-decoration: none;
    }
}
