@import '../../../../styles/common/common.scss';

.container {
    width: 100%;
    position: absolute;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--utmb-zindex-nav);
}

.static_container {
    position: relative;
}

.nav {
    display: none;
    width: 100%;
    height: var(--menu-height);
    background-color: transparent;
    justify-content: space-between;
    position: absolute;
    top: 0;
    z-index: var(--utmb-zindex-nav);
    padding: 0 rem(20px) !important;

    @include bp(lg) {
        display: flex;
        padding: 0;
    }

    a {
        color: var(--utmb-color-default);
    }
    
    .emphasis {
        border: 1px solid var(--utmb-color-border-gray);
        border-radius: rem(4px);
        padding: rem(8px);
        background-color: var(--utmb-color-primary);
        align-self: auto;
        height: fit-content;
        
        color: var(--utmb-color-white);

        > a > span {
            color: var(--utmb-color-white);
        }
    }
    .menuLink {
        display: flex;
        width: 100%;
        height: 100%;
    }
    .emphasis:hover {
        > span {
            font-weight: bold;
        }
    }

    li:has(> div > div > a.currentActiveMenu) {
        > button {
            > span {
                /*font-family: 'Futura PT Heavy', serif;*/
                font-weight: bold;
            }
        }
    }

    .utmbws {
        height: 100%;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        > span {
            max-height: 3rem;
            top: 50% !important;
            transform: translateY(-50%);
        }
    }

    .event_date {
        position: absolute;
        display: flex;
        align-items: center;
        height: 3rem;
        top: var(--menu-sticky-height);
        transform: translateY(0.7rem) translateX(1px);
        right: 0.6rem;
        padding: 0 1.5em;
        color: var(--utmb-color-default);
        text-transform: uppercase;
        z-index: 1000;

        &:before {
            content: '';
            border: 1px solid #9da7af;
            border-top: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform: skewX(-24deg);
        }
    }
    .event_date_simple {
        top: var(--menu-sticky-height-simple);
        &:before {
            top: 0;
        }
    }

    .event_date_notLanding {
        &:before {
            background-color: var(--utmb-color-white);
            z-index: -1;
        }
    }

    &.transparent {
        li,
        a {
            color: var(--utmb-color-white);
        }
        &.open {
            a,
            li {
                color: var(--utmb-color-default);
            }
        }
        .event_date {
            color: var(--utmb-color-white);
            &:before {
                // border-color: var(--utmb-color-white);
            }
        }
    }

    &.open {
        background-color: var(--utmb-color-white);
        transform: none !important;
        .event_date {
            color: var(--utmb-color-default);

            &:before {
                border: 1px solid #9da7af;
                background-color: var(--utmb-color-white);
                z-index: -1;
            }
        }
        &.sticky {
        }
    }

    .logo {
        align-items: center;
        display: flex;
        height: var(--menu-height);
        width: rem(100px);

        &_img_wrapper {
            height: rem(50px);
            position: relative;
            width: rem(100px);
        }
    }

    &.sticky {
        top: var(--top-bar-height);
        background: var(--utmb-color-white);
        height: var(--menu-sticky-height) !important;
        box-shadow: 0 0 rem(15px) rgba(0, 0, 0, 0.2);
        transition: transform 0.5s;

        &.sticky_hidden {
            transform: translateY(-150%);
        }

        &.no_shadow {
            box-shadow: none;
        }

        &.open {
            height: auto !important;
        }
        .menu {
            min-height: auto !important;
            height: var(--menu-sticky-height) !important;
        }
        a,
        li {
            color: var(--utmb-color-default);
        }
        .logo {
            height: var(--menu-sticky-height) !important;
        }
        .event_date {
            top: var(--menu-sticky-height);
            color: var(--utmb-color-default);
            transform: none;

            &:before {
                background-color: var(--utmb-color-white);
                border:none;
                z-index: -1;
                box-shadow: 0 0 1.5rem rgb(0 0 0 / 20%);
                clip-path: inset(0 -1.5rem -1.5rem -1.5rem);
            }
        }
    }
    &.navSimple {
        &:not(.sticky) .event_date {
            transform: translateY(0.4rem);
        }
        &.sticky {
            .event_date {
                &:before {
                    border: 1px solid #9da7af;
                    border-top: none;
                }
            }
        }
    }

    .currentActiveMenu {
        > button > span {
            font-family: var(--utmb-font-futura-heavy), serif;
        }
    }
}

.menuSimple {
    height: 7rem;
    
    > .active {
        border-bottom: none !important;
    }

    .simple_active {
        position: relative;
        > button {
            width: 100%;
            &:not(.emphasis):after {
                content: '';
                width: 0;
                height: 2px;
                background-color: var(--utmb-color-primary);
                position: absolute;
                bottom: 20px;
                left: 50%;
                transform: translateX(-50%);
                transition: width 0.15s ease-in-out;
            }
            &:hover:after {
                width: 100%;
                transition: width 0.35s ease-in-out;
            }
        }

        &:after {
            content: '';
            width: 0;
            height: 2px;
            background-color: var(--utmb-color-primary);
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            transition: width 0.15s ease-in-out;
        }
        &.active:after {
            width: 100%;
            transition: width 0.35s ease-in-out;
        }
    }

    > li {
        flex: 1;
    }

    > .hasLink:hover {
        > button {
            > span {
                font-weight: bold;
                cursor: pointer;
            }
        }
    }

    > li {
        margin-right: 0 !important;
    }
}

.boldOnHover:hover {
    > span {
        font-weight: bold;
    }
}

.hasNoLink:hover {
    cursor: default;
}

.wrapper_simple {
    border-bottom: 1px solid #9da7af;
}
.wrapper {
    width: 100%;
    position: relative;

    @include bp(lg) {
        width: calc(100% - rem(132px));
        height: 100%;
        margin-left: rem(20px);
    }

    @include bp(xl) {
        width: calc(100% - rem(162px));
        margin-left: rem(50px);
    }

    .separatorAngle {
        transform-origin: top right;
        transform: rotate(114deg);
        position: absolute;
        right: 0;
        margin: 0 !important;
        /*top: var(--menu-height);*/
    }
}

.menu {
    z-index: var(--utmb-zindex-max);
    display: flex;
    align-items: center;
    list-style: none;
    width: 100%;
    max-width: rem(1133px);
    min-height: var(--menu-height);
    position: relative;

    li {
        align-self: stretch;
        align-items: center;
        /*min-width: rem(44px);*/
        display: flex;

        > button {
            // RESET
            border: none;
            margin: 0;
            padding: 0;
            width: auto;
            overflow: visible;

            background: transparent;

            /* inherit font & color from ancestor */
            color: inherit;
            font: inherit;

            /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
            line-height: normal;

            /* Corrects font smoothing for webkit */
            -webkit-font-smoothing: inherit;
            -moz-osx-font-smoothing: inherit;

            /* Corrects inability to style clickable `input` types in iOS */
            -webkit-appearance: none;

            // ADD NEW RULES
            cursor: pointer;
            height: 100%;
        }

        span {
            pointer-events: none;
        }

        &:not(:last-of-type) {
            @include bp(md) {
                margin-right: 1.5rem;
            }
            @include bp(xl) {
                margin-right: rem(40px);
            }
        }

        > button {
            width: 100%;
            position: relative;

            &:not(.emphasis):after {
                content:'';
                background-color: var(--utmb-color-default);
                width: 0%;
                position: absolute;
                display: block;
                bottom:0;
                height: 0.3rem;
                left: 50%;
                transform: translateX(-50%);
                transition: width 0.15s ease-in-out;
            }
        }

        &.active {
            /*border-bottom: rem(3px) solid var(--utmb-color-default);
            text-underline-offset: rem(20px);*/
            
            > .simplemegamenu {
                border: none;
            }

            > button {
                span {
                    font-weight: bold;
                }
                &:after {
                    width: 100%;
                    transition: width 0.35s ease-in-out;
                }
            }
        }

        a {
            // display: flex !important;
            // height: 100%;
            align-self: stretch;
            align-items: center;
            text-decoration: none;
            letter-spacing: rem(1px);
        }
    }
}

.search {
    align-self: flex-end;
}

.breadcrumbs {
    margin-top: rem(16px);
}
.breadcrumbsSimple {
    display: none;
}

.megamenu {
    background-color: var(--utmb-color-white);
    display: none;
    flex-wrap: nowrap;
    left: 0;
    max-width: rem(985px);
    padding: 3rem 0 2rem 0;
    overflow-x: visible;
    position: absolute;
    top: 100%;
    width: 100%;
    box-shadow: 0 rem(15px) rem(15px) rem(-15px) rgba(0, 0, 0, 0.2);

    &:before,
    &:after {
        z-index: -1;
    }

    &:before {
        background-color: var(--utmb-color-white);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transform: translateX(-100%);
        width: 100vw;
        box-shadow: rem(30px) rem(15px) rem(15px) rem(-15px) rgba(0, 0, 0, 0.2);
    }
    &:after {
        background-color: var(--utmb-color-white);
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(100%);
        width: 100vw;
        box-shadow: rem(-30px) rem(15px) rem(15px) rem(-15px) rgba(0, 0, 0, 0.2);
    }

    .active & {
        display: flex;
    }

    /* 3 COLUMNS */
    .column {
        border-right: rem(1px) solid var(--utmb-color-gray);
        display: flex;
        flex-basis: 33.33%;
        flex-direction: column;
        list-style: none;
        margin-right: rem(40px);
        padding-right: rem(40px);

        &:last-child {
            border-right: none;
        }

        a {
            color: currentColor;
            text-decoration: none;
            &:not(:last-of-type) {
                margin-bottom: rem(12px);
            }
            &:hover {
                text-decoration: underline;
                text-underline-offset: rem(3px);
            }
        }

        & > p {
            margin-bottom: rem(15px);
        }

        .thumbnail {
            min-width: rem(275px);
            width: 100%;
            height: rem(150px);
            position: relative;
            margin-bottom: rem(15px);
            transition: box-shadow 0.5s;
        }
    }

    .columnSimple {
        border-right: rem(1px) solid var(--utmb-color-gray);
        display: flex;
        flex-direction: column;
        list-style: none;
        margin-right: rem(20px);
        padding-right: rem(20px);

        &:last-child {
            border-right: none;
        }

        a {
            color: currentColor;
            text-decoration: none;
            &:not(:last-of-type) {
                margin-bottom: rem(12px);
            }
            &:hover {
                text-decoration: underline;
                text-underline-offset: rem(3px);
            }
        }

        & > p {
            margin-bottom: rem(15px);
        }

        .thumbnail {
            min-width: rem(275px);
            width: 100%;
            height: rem(150px);
            position: relative;
            margin-bottom: rem(15px);
            transition: box-shadow 0.5s;
        }
    }

    .full_height {
        height: 100% !important;
    }

    .link_full_height {
        margin-bottom: 0 !important;
        height: 100%;
    }

    .link {
        @include hover-focus() {
            text-decoration: underline;
        }
    }
}

.simplemegamenu {
    background-color: rgba(255, 255, 255, 0.9);
    display: none;
    flex-wrap: nowrap;
    padding: 1rem 0;
    overflow-x: visible;
    position: absolute;
    top: 100%;
    width: auto;
    min-width: 100%;
    max-width: 40vw;
    left: inherit;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0 0 5px 5px;

    &:before,
    &:after {
        z-index: -1;
    }

    .active & {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        //box-shadow: rem(-30px) rem(15px) rem(15px) rem(-15px) rgba(0, 0, 0, 0.2);
        box-shadow: -1rem 3rem 3rem 1.5rem rgb(0, 0, 0, .15);
    }

    .columnSimple {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding-right: 1.5rem;
        padding-left: 1.5rem;

        a {
            color: currentColor;
            text-decoration: none;
            &:not(:last-of-type) {
                margin-bottom: rem(12px);
            }
            > p {
                width: auto;
                margin: auto;
                white-space: nowrap;
                position: relative;
                line-height: 2.5rem;
                text-align: center;

                &:after {
                    content: '';
                    width: 0;
                    height: 2px;
                    background-color: var(--utmb-color-primary);
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: width 0.15s ease-in-out;
                }
            }
            &:hover {
                text-decoration: none;
                > p:after {
                    width: 100%;
                    transition: width 0.35s ease-in-out;
                }
            }
        }

        & > p {
            margin-bottom: rem(15px);
        }
    }

    .full_height {
        height: 100% !important;
    }

    .link_full_height {
        margin-bottom: 0 !important;
        height: 100%;
    }

    .link {
        @include hover-focus() {
            text-decoration: underline;
        }
    }
}

.column_empty {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: var(--utmb-color-white);
    list-style: none;
    min-width: rem(275px);
    height: rem(372px) !important;

    div {
        width: rem(275px);
        height: rem(372px) !important;
        background-size: cover !important;
    }
}

.subtitle {
    letter-spacing: 0.55px;
}

.nav_container {
    display: flex;
    justify-content: space-between;
    margin: auto;
}
