@import '../../styles/common/common.scss';

$container-width: rem(150px) !default;
$container-width-mobile: rem(100px) !default;

.container {
    display: flex;
    align-items: center;
    width: $container-width-mobile;
    height: var(--partners-menu-container-height-mobile);

    @include bp(lg) {
        padding: 0 0 0 rem(20px);
        margin-left: rem(20px);
        min-width: calc($container-width + rem(20px));
        max-width: calc($container-width + rem(20px));
        height: auto;
        max-height: var(--menu-height);
    }

    html[style*='--menu-is-sticky:true;'] & .image,
    html[style*='--menu-is-opened:true;'] & .image,
    html[style*="--menu-is-sticky: true;"] & .image,
    html[style*="--menu-is-opened: true;"] & .image {
        &_light {
            display: block !important;
            &[class*="hidden"] {
                display: block !important;
            }
        }

        &_dark {
            display: none;
        }
    }

    .image {
        &_container {
            position: absolute;
            transition: opacity 0.3s;
            width: $container-width-mobile;
            height: calc(var(--partners-menu-container-height-mobile) - rem(10px));

            @include bp(lg) {
                width: $container-width - rem(20px);
                height: calc(var(--menu-sticky-height) - rem(10px));
            }

            &.hidden {
                opacity: 0;
                visibility: hidden;
            }
        }

        &_wrapper {
            height: 100%;
        }

        &_light,
        &_dark {
            position: relative;
            height: 100%;
        }
    }
}
