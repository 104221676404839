@import '../styles/common/common.scss';

// Page Section Defaults
//
// Note: ALL CONTENT BETWEEN NAVIGATION AND FOOTER ARE NOW WRAPPED IN <main className={styles.content}></main>
//       - <section className={styles.content}> occurences can be DELETED now, unless they contain additional rules
//
.content {
    @extend .content_base;
    padding: 0;
}

.content_base {
    width: 100%;
    min-height: calc(100vh - rem(53px));
    height: auto;

    @include bp(md) {
        min-height: calc(100vh - rem(217px));
    }
}

.mobile_header {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    overflow: hidden;

    @include bp(md) {
        display: none !important;
    }
}
